<template>
  <div class="search-wrapper form-group">

    <!-- Dropdown Input -->
    <input v-bind:required="isRequired" class="form-control form-control-lg search-input"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder" 
      autocomplete="off"
      
      
      />

    <div class="dropdown-content"
      v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index">
          {{ option[label] }}
      </div>
    </div>
 </div>

</template>

<script>

  import api from '@/util/api.js';


  export default {
    name: 'searchInput',
    props: {
      
      name: {
        type: String,
        required: false,
        default: 'search_input',
        
      },
      id:{
        type:String,
        default:'id'
      },
      label:{
        type:String,
        default:'name',
      },    
      options: {
        type: Array,
        required: false,
        //default: [],
        
      },

      ajax:{
        type:String,
        required:false
      },

      placeholder: {
        type: String,
        required: false,
        default: 'Selecciona una opción',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      defaultValue:{

        type:Number,
        required:false


      },
      defaultLabel:{

        type:String,
        required:false


      },

      required:{
        type:Boolean,
        required:false,
        default:false,

      },
      maxItem: {
        type: Number,
        required: false,
        default: 6,

      },



    },
    data() {
      return {

        ajaxTimer:0,
        filteredOptions:[],
        selected: {},
        optionsShown: false,
        searchFilter: '',
        
      }
    },
    created() {
      this.$emit('onselect', this.selected);
    },
    computed: {

      isRequired(){


        return this.$props.required;

      },


    },
    methods: {


      normalSearch(v){
          

        const filtered=[];
          //Truco para ignorar acentos en la coincidencia de texto
          const normalized_label=v.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  
          const escaped_label =this.escapeRegExp(normalized_label);
          const regOption = new RegExp(escaped_label, 'ig');
          let label;
          for (const option of this.options) {
              
              label=option[this.$props.label].normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            
            if (v.length < 1 || label.match(regOption)){
              if (filtered.length < this.maxItem) filtered.push(option);
            }
          }


          this.filteredOptions=filtered;
          
        },


      ajaxSearch(q) {


        clearTimeout(this.ajaxTimer);

          this.ajaxTimer = setTimeout(() => {
            this.queryAjaxItems(q);
          }, 500);

      },

      queryAjaxItems(q){
        api.get(this.$props.ajax+'?filter['+this.$props.label+'][like]='+q+'&limit=1')
          .then(response => {
            
              this.processAjaxResults(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      },


      processAjaxResults(data){
        const options=[];

                  
          for (var i in data){
            options[i]=data[i];
            options[i][this.$props.id]=data[i][this.$props.id];
            options[i][this.$props.label]=data[i][this.$props.label];
          }



          
          this.filteredOptions=options;
      },



      escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); 
      },

      selectOption(option) {
        
        this.selected = option;
        this.optionsShown = false;
        this.searchFilter = this.selected[this.$props.label];
    
        this.$emit('onselect', this.selected);
      },
      showOptions(){
        if (!this.disabled) {
          this.searchFilter = '';
          this.filteredOptions=[];
          this.optionsShown = true;
          
        }
      },
      exit() {
          
          this.optionsShown = false;
/*
        let that=this;
        if (!that.selected[that.$props.id]) {
          that.selected = {};
          that.searchFilter = '';
        } else {
          that.searchFilter = that.selected[that.$props.label];
        }
        that.$emit('selected', that.selected);
*/
      },

      keyMonitor: function(event) {
        if (event.key === "Enter" && this.filteredOptions[0])
          this.selectOption(this.filteredOptions[0]);
      }
    },
    watch: {


        searchFilter(v){

            this.$emit('value',v);
            if(this.$props.ajax){
              this.ajaxSearch(v);
            }
            else{
              this.normalSearch(v);
            }

        },

        options:function(){

            this.searchFilter='';


        },
        defaultValue:function(){

                
            const index=this.options.findIndex(e=>{
                return e.id==this.$props.defaultValue;
            });

            const option=this.options[index];

            if(option!=undefined){
                this.selectOption(option);

            }
        }
        /*
      searchFilter() {
        
        if (this.filteredOptions.length === 0) {
          this.selected = {};
        } else {
          this.selected = this.filteredOptions[0];
        }
        this.$emit('filter', this.searchFilter);
      }*/
    },

    mounted(){



      if(this.options){
        this.filteredOptions=this.options;
        
      }





      
        if(this.$props.ajax && this.$props.defaultLabel){

          
          this.filteredOptions=[{id:this.$props.defaultValue,name:this.$props.defaultLabel}];

          this.searchFilter=this.$props.defaultLabel;

          
          /*
            api.get(this.$props.ajax+'/'+this.$props.defaultValue).then(response=>{
  
              this.processAjaxResults(response.data);


            }).error(error=>{

                console.log(error);

            }).finally(()=>{


            });*/

          
        }


      


    }


    
  };
</script>


<style lang="scss" scoped>
  .search-wrapper {
    position: relative;
    display: block;
    margin: auto;

    .search-input{padding:0.5rem 1.5rem 0.5rem .75rem}
    .dropdown-input {
      background: #fff;
      cursor: pointer;
      border: 1px solid #e7ecf5;
      border-radius: 3px;
      color: #333;
      display: block;
      font-size: .8em;
      padding: 6px;
      min-width: 250px;
      max-width: 250px;
      &:hover {
        background: #f8f8fa;
      }
    }
    .dropdown-content {
      position: absolute;
      background-color: #fff;
      min-width: 248px;
      max-width: 248px;
      max-height: 248px;
      border: 1px solid #e7ecf5;
      box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
      overflow: auto;
      z-index: 1;
      .dropdown-item {
        color: black;
        font-size: .7em;
        line-height: 1em;
        padding: 8px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        &:hover {
          background-color: #e7ecf5;
        }
      }
    }
    .dropdown:hover .dropdowncontent {
      display: block;
    }
  }
</style>