<template>

            <form @submit.prevent="save" ref="formExpense">

                <div class="form-group">
                    <label for="name">Concepto *</label>
                    <input type="text" v-model="model.concept" required id="name" class="form-control form-control-lg" placeholder="Escribe el concepto del gasto">
                </div>                        
                
                <div class="form-group">
                    <label for="name">Descripción</label>
                    <textarea class="form-control" rows="5" v-model="model.description"></textarea>
                </div>                        
                
                <div class="form-group">
                    <div class="form-group">
                            <label for="name">Desarrollo *</label>
                            <select class="form-control form-contol-lg" @change="onDevelopmentChange" ref="developmentControl" v-bind:required="!$props.development_id" v-model="model.development_id">
                                <option selected value="">Selecciona</option>
                            <option v-for="(option, index) in developments" :key="index" :value="option.id">{{ option.name }}</option>
                            </select>
                    </div>
                </div>
                <div class="form-group">
                    <label>Selecciona la categoría *</label>
                    <searchInput :key="search_input_category" @onselect="onCategorySelection" :required="true" :defaultValue="parseInt(model.budget_id)" :options="getCategories"/>
                    
                </div>
            
                <div class="form-group">
                    <label for="name">Importe *</label>
                    <input type="text" v-number-format v-model="model.amount" required id="name" class="form-control form-control-lg" placeholder="Escribe el importe">
                </div>

                <div class="form-group">
                    <label>Fecha programada de pago *</label>
                    <Datepicker :inputClass="'form-control form-control-lg'" :position="'top'" lang="es" v-model="model.due_date" placeholder="Selecciona la fecha"/>

                </div>


                <div class="form-group">
                    <label>Estatus *</label>
                    <select v-model="model.status" required class="form-control">
                        <option value="">Selecciona</option>
                        <option value="pending">Pendiente</option>
                        <option value="approved">Aprobado</option>
                        <option value="refused">Rechazado</option>
                        <option value="cancelled">Cancelado</option>
                        <option value="paid">Pagado</option>
                    </select>
                    
                </div>


                <div class="form-group">
                    <label>Forma de pago</label>
                    <select class="form-control" v-model="model.fund_id">
                        <option value="">Selecciona</option>
                        <option v-for="(item,index) in funds" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>

                    
                </div>



                <div class="form-group">
                    <label>Selecciona el beneficiario</label>
                    <searchInput @onselect="onVendorSelection" :defaultValue="parseInt(model.vendor_id)" :options="getVendors"/>
                    
                </div>



                <div class="form-group"><small>* campos requeridos</small></div>


                

                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import searchInput from '@/components/searchInput';
import api from '@/util/api.js';



export default {
    routeName:'expenses',
    name: 'formExpense',
    components:{
        submitbutton,
        searchInput
        
    },

    props:{

        id:{
            type:Number,
            required:false,
        },
        development_id:{
            type:Number,
            required:false

        }


    },  

    computed:{

        isModelReady(){
            
            return this.isNewRecord || (this.model.id!=undefined && this.model.id);
        },  

        getDevelopments(){

            return this.developments;
        },
        

        getCategories(){
            const items=this.categories.filter(item=>item.development_id==this.model.development_id);
            return items;
        },
        getVendors(){
                return this.vendors;
        },
        getFunds(){
                return this.funds;
        }


    },
    data(){

        return {

            search_input_category:0,
            categories:[],
            vendors:[],
            funds:[],
            developments:[],
            model:{
                id:'',
                concept:'',
                description:'',
                amount:'',
                budget_id:'',
                development_id:'',
                vendor_id:'',
                fund_id:'',
                due_date:new Date(),
                status:'pending'

            },
            
            pic:'',
            loading:false,


        }

    },

    methods:{


        onDevelopmentChange(){
            this.search_input_category++;
        },

        onCategorySelection(item){

            this.model.budget_id=item.id;
            
        },
        onVendorSelection(item){
            
            this.model.vendor_id=item.id;

        },

        save(){

            

            if(this.validateForm(this.$refs.formExpense)){


                    this.model.due_date=this.$dateToString(this.model.due_date);
                    const data = this.createFormData(this.model);
                    this.loading=true;

                    api.request({
                        url:this.me(this.isNewRecord?'/':this.model.id),
                        data,
                        headers:{'Content-type':'form/data'},
                        method:this.isNewRecord?'POST':'PATCH',
                        }).then(response=>{

                            this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                            this.$emit('afterSave',response);
                            
                            
                        }).catch(error=>{
                            
                            this.$notify({message:"Ocurrió un error",type:"error"});
                            console.log(error);

                        }).finally(()=>{

                                this.loading=false;
                        });

            }
            
        }




    },


    
    
    mounted(){



        
        const development_id=this.$props.development_id;

        if(development_id){
            this.model.development_id=development_id;
        }





        
        



        api.get(this.me('/parentcatalogs')).then(response=>{

                this.categories=response.data.categories;
                this.funds=response.data.funds;
                this.vendors=response.data.vendors;
                this.developments=response.data.developments;

                if(!this.isNewRecord){
                   return api.get(this.me(this.$props.id))
                }
            }).then(response=>{
                    
                    const data=response.data;

                    for (const key in data) {
                        if (this.model.hasOwnProperty(key)) {
                            this.model[key] = data[key];
                        }
                    }


            }).catch(error=>{
                console.log(error);
            }).finally(()=>{


            });





    }


}

</script>